exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-us-city-page-tsx": () => import("./../../../src/templates/AboutUsCityPage.tsx" /* webpackChunkName: "component---src-templates-about-us-city-page-tsx" */),
  "component---src-templates-architect-zone-page-tsx": () => import("./../../../src/templates/ArchitectZonePage.tsx" /* webpackChunkName: "component---src-templates-architect-zone-page-tsx" */),
  "component---src-templates-blinds-and-shutters-page-tsx": () => import("./../../../src/templates/BlindsAndShuttersPage.tsx" /* webpackChunkName: "component---src-templates-blinds-and-shutters-page-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../../../src/templates/CategoryPage.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */),
  "component---src-templates-front-door-page-tsx": () => import("./../../../src/templates/FrontDoorPage.tsx" /* webpackChunkName: "component---src-templates-front-door-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-home-stories-page-tsx": () => import("./../../../src/templates/HomeStoriesPage.tsx" /* webpackChunkName: "component---src-templates-home-stories-page-tsx" */),
  "component---src-templates-inspirations-page-tsx": () => import("./../../../src/templates/InspirationsPage.tsx" /* webpackChunkName: "component---src-templates-inspirations-page-tsx" */),
  "component---src-templates-model-page-tsx": () => import("./../../../src/templates/ModelPage.tsx" /* webpackChunkName: "component---src-templates-model-page-tsx" */),
  "component---src-templates-partner-zone-page-tsx": () => import("./../../../src/templates/PartnerZonePage.tsx" /* webpackChunkName: "component---src-templates-partner-zone-page-tsx" */),
  "component---src-templates-passive-windows-page-tsx": () => import("./../../../src/templates/PassiveWindowsPage.tsx" /* webpackChunkName: "component---src-templates-passive-windows-page-tsx" */),
  "component---src-templates-realizations-page-tsx": () => import("./../../../src/templates/RealizationsPage.tsx" /* webpackChunkName: "component---src-templates-realizations-page-tsx" */),
  "component---src-templates-sliding-doors-and-panoramic-glazing-page-tsx": () => import("./../../../src/templates/SlidingDoorsAndPanoramicGlazingPage.tsx" /* webpackChunkName: "component---src-templates-sliding-doors-and-panoramic-glazing-page-tsx" */),
  "component---src-templates-special-offer-page-tsx": () => import("./../../../src/templates/SpecialOfferPage.tsx" /* webpackChunkName: "component---src-templates-special-offer-page-tsx" */),
  "component---src-templates-warranty-page-tsx": () => import("./../../../src/templates/WarrantyPage.tsx" /* webpackChunkName: "component---src-templates-warranty-page-tsx" */),
  "component---src-templates-why-internorm-details-page-tsx": () => import("./../../../src/templates/WhyInternormDetailsPage.tsx" /* webpackChunkName: "component---src-templates-why-internorm-details-page-tsx" */),
  "component---src-templates-why-internorm-page-tsx": () => import("./../../../src/templates/WhyInternormPage.tsx" /* webpackChunkName: "component---src-templates-why-internorm-page-tsx" */),
  "component---src-templates-window-guide-page-tsx": () => import("./../../../src/templates/WindowGuidePage.tsx" /* webpackChunkName: "component---src-templates-window-guide-page-tsx" */),
  "component---src-templates-window-guides-page-tsx": () => import("./../../../src/templates/WindowGuidesPage.tsx" /* webpackChunkName: "component---src-templates-window-guides-page-tsx" */)
}

